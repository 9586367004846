import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/apps/magicsquare/components/layouts/Main/Main.tsx");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/apps/magicsquare/components/providers/GlobalModalsMagicsquare/GlobalModalsMagicsquare.tsx");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/apps/magicsquare/components/providers/MixPanelProviderMagicsquare.tsx");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/apps/magicsquare/styles/global.css");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/libs/shared/src/common/providers/AuthProvider.tsx");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/libs/shared/src/common/providers/GlobalModalsProvider.tsx");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/libs/shared/src/common/providers/GlobalStateProvider.tsx");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/libs/shared/src/common/providers/GoogleTagManagerProvider.tsx");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/libs/shared/src/common/providers/PasskeyProvider.tsx");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/libs/shared/src/common/providers/ReactQuery/QueryProviderSsr.tsx");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/libs/shared/src/common/providers/ToastProvider.tsx");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/libs/shared/src/common/providers/TonProvider.tsx");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/libs/shared/src/common/providers/UserAgentDetectProvider.tsx");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/libs/shared/src/common/providers/Web3Provider/Web3Provider.tsx");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-primary\",\"adjustFontFallback\":true,\"preload\":true}],\"variableName\":\"primaryFont\"}");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Outfit\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-secondary\",\"adjustFontFallback\":true,\"preload\":true}],\"variableName\":\"secondaryFont\"}")