import { MIXPANEL_EVENT_NAMES } from '@shared/api/mixPanel';
import { nextProjectUrl } from '@shared/constants/url';

export const products = [
  {
    id: 'sqr-token-docs',
    label: 'SQR Token',
    href: 'https://docs.magic.store/sqr-token/sqr-token',
  },
  {
    id: 'store',
    label: 'Magic Store',
    href: nextProjectUrl.store('/'),
  },
  {
    id: 'hot-offers',
    label: 'Hot Offers',
    href: nextProjectUrl.store('/hot-offers'),
  },
  {
    id: 'magic-board',
    label: 'Magic Board',
    href: nextProjectUrl.store('/magic-board'),
  },
  {
    id: 'reward-pools',
    label: 'Reward Pools',
    href: nextProjectUrl.store('/reward-pools'),
  },
  {
    id: 'launchpad',
    label: 'Launchpad',
    href: nextProjectUrl.launchpad('/'),
    mixPanelEventName: MIXPANEL_EVENT_NAMES.LAUNCHPAD_LINK_CLICK,
  },
  {
    id: 'staking',
    label: 'Staking',
    href: nextProjectUrl.staking('/'),
    mixPanelEventName: MIXPANEL_EVENT_NAMES.STAKING_LINK_CLICK,
  },
  {
    id: 'swap',
    label: 'Swap',
    href: process.env.NEXT_PUBLIC_SWAP_HOST || 'https://magicsquare.io/swap',
  },
  {
    id: 'boost',
    label: 'Boost',
    href: nextProjectUrl.store('/magic-boost'),
  },
];

export const developers = [
  {
    id: 'apply-for-grant',
    label: 'Apply for a Grant',
    href: 'https://docs.google.com/forms/d/e/1FAIpQLSeOc1yitrOBkGal-SAgAPky5OQ0a8RiJM0_dbxEXXdC0z3RNQ/viewform',
  },
  {
    id: 'list-project',
    label: 'List your Project',
    href: 'https://magicsquare.io/submit-your-app/',
  },
  {
    id: 'run-campaign',
    label: 'Run a Campaign',
    href: 'https://docs.magic.store/for-project-owners-developers/how-do-you-run-a-hot-offer-campaign',
  },
  {
    id: 'widget',
    label: 'Score Widget',
    href: 'https://docs.magic.store/for-project-owners-developers/how-to-add-app-widgets',
  },
];

export const company = [
  {
    id: 'about',
    label: 'About',
    href: 'https://magicsquare.io/about-us/',
  },
  {
    id: 'roadmap',
    label: 'Roadmap',
    href: 'https://docs.magic.store/roadmap/roadmap',
  },
  {
    id: 'lite-paper',
    label: 'Lite paper',
    href: 'https://magicsquare.gitbook.io/magic-square-litepaper',
  },
  {
    id: 'knowledge-base',
    label: 'Knowledge base',
    href: 'https://docs.magic.store/',
  },
  {
    id: 'blog',
    label: 'Blog',
    href: 'https://medium.com/@magicsquare',
  },
  {
    id: 'career',
    label: 'Careers opportunities',
    href: 'https://career.magicsquare.io/',
  },
  {
    id: 'media-kit',
    label: 'Media Kit',
    href: 'https://docs.magic.store/library/brand-and-logos',
  },
];

export const contacts = [
  {
    id: 'official-links',
    label: 'Official links',
    href: 'https://linktr.ee/magicsquare',
  },
  {
    id: 'feedback',
    label: 'Give us feedback',
    href: 'https://magicsquare.featureos.app/',
  },
  {
    id: 'apply-kol',
    label: 'Apply as a KOL',
    href: 'https://forms.gle/9c1ThUzLUka1wK2KA',
  },
  {
    id: 'support',
    label: 'User enquiries & Support',
    href: 'https://discord.com/invite/magicsquareio',
  },
  {
    id: 'business',
    label: 'Business enquiries',
    href: 'https://docs.google.com/forms/d/e/1FAIpQLSdvghj8pQkSfJl-KNQh7VKs5Zmq40OGPzICraYtHXTIg5SxFw/viewform?usp=send_form',
  },
];
